import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import SEO from "../components/seo"

export const Container = styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 16px;
    margin-right: 16px;
    @media (min-width: 1280px) {
        margin-left: 15%;
        margin-right: 15%;
  }
`

export const H1 = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
`

export const P = styled.p`
    margin-top: 1rem;
    margin-bottom: 1rem;
`

export const OL = styled.ol`

`

export const LI = styled.li`
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 2rem;
`

const AGB = () => {
    return (
        <Layout>
            <SEO title="Allgemeine Geschäftbedingungen"/>
            <Container>
                <H1>Allgemeine Geschäftbedingungen</H1>
                <h2>I. Vertragsabschluss</h2>
                <OL>
                    <LI>Der Käufer ist bei nicht vorrätiger Ware drei Wochen an die Bestellung (Vertragsangebot) gebunden.</LI>
                    <LI>Mit Ablauf dieser Frist kommt der Vertrag zustande, wenn der Verkäufer das Vertragsangebot nicht vorher schriftlich abgelehnt hat.</LI>
                    <LI>Abweichend von Ziff. 2. kommt der Vertrag schon vor Ablauf der Dreiwochenfrist zustande, wenn der Vertrag beiderseits unterschrieben wird, oder der Verkäufer schriftlich die Annahme der Bestellung (des Vertragsangebots) erklärt oder der Verkäufer Vorauszahlungen auf den Kaufpreis annimmt.</LI>
                </OL>
                <h2>II. Preise</h2>
                <OL>
                    <LI>Die Preise sind Festpreise einschließlich Mehrwertsteuer.</LI>
                    <LI>Besondere, zusätzlich vereinbarte Arbeiten, die nicht im Kaufpreis enthalten sind, wie z. B. Dekorationsarbeiten, werden zusätzlich in Rechnung gestellt und spätestens bei Übergabe bzw. Abnahme zur Zahlung fällig. Hierunter fallen u.a. auch vom Kunden gewünschte Verblendungsarbeiten</LI>
                </OL>
                <h2>III. Änderungsvorbehalt</h2>
                <OL>
                    <LI>Serienmäßig hergestellte Möbel werden nach Muster oder Abbildung verkauft.</LI>
                    <LI>Es besteht kein Anspruch auf Lieferung der Ausstellungsstücke, es sei denn, dass bei Vertragsabschluß eine anderweitige Vereinbarung erfolgt ist.</LI>
                    <LI>Es können an die bestellten Waren qualitativ Ansprüche nur in einer Höhe gestellt werden, wie sie billigerweise oder handelsüblich bei Waren in der Preislage der bestellten gestellt werden können.</LI>
                    <LI>Handelsübliche und für den Käufer zumutbare Farb- und Maserungsabweichungen bei Holzoberflächen bleiben vorbehalten.</LI>
                    <LI>Ebenso bleiben handelsübliche und für den Käufer zumutbare Abweichungen bei Leder und Textilien (z. B. Möbel- und Dekorationsstoffen) vorbehalten hinsichtlich geringfügiger Abweichungen in der Ausführung gegenüber Leder- und Stoffmustern, insbesondere im Farbton.</LI>
                    <LI>Auch handelsübliche und für den Käufer zumutbare Abweichungen von Maßdaten bleiben vorbehalten.</LI>
                </OL>
                <h2>IV. Montage</h2>
                <OL>
                    <LI>Hat der Verkäufer hinsichtlich der Montage aufzuhängender Einrichtungsgegenstände Bedenken wegen der Eignung der Wände, so hat er dies dem Käufer vor der Montage mitzuteilen.</LI>
                    <LI>Die Mitarbeiter des Verkäufers sind nicht befugt, Arbeiten auszuführen, die über die vertragsgegenständlichen Leistungsverpflichtungen des Verkäufers hinausgehen. Werden dennoch solche Arbeiten auf Verlangen des Käufers von den Mitarbeitern des Verkäufers ausgeführt, berührt dies nicht das Vertragsverhältnis zwischen Verkäufer und Käufer.</LI>
                </OL>

                <h2>V. Lieferfrist</h2>
                <OL>
                    <LI>Hat der Verkäufer hinsichtlich der Montage aufzuhängender Einrichtungsgegenstände Bedenken wegen der Eignung der Wände, so hat er dies dem Käufer vor der Montage mitzuteilen.</LI>
                    <LI>Die Mitarbeiter des Verkäufers sind nicht befugt, Arbeiten auszuführen, die über die vertragsgegenständlichen Leistungsverpflichtungen des Verkäufers hinausgehen. Werden dennoch solche Arbeiten auf Verlangen des Käufers von den Mitarbeitern des Verkäufers ausgeführt, berührt dies nicht das Vertragsverhältnis zwischen Verkäufer und Käufer.</LI>
                </OL>

                <h2>VI. Eigentumsvorbehalt</h2>
                <OL>
                    <LI>Die Ware bleibt bis zur vollständigen Erfüllung aller Verbindlichkeiten aus diesem Vertragsverhältnis Eigentum des Verkäufers. (2) Der Käufer verpflichtet sich, das Eigentum des Verkäufers auch dann entsprechend zu wahren, wenn die gelieferten Waren nicht unmittelbar für den Käufer, sondern für Dritte bestimmt sind, und hat den Empfänger auf diesen Eigentumsvorbehalt ausdrücklich hinzuweisen.</LI>
                    <LI>Jeder Standortwechsel und Eingriffe Dritter, insbesondere Pfändungen, sind dem Verkäufer unverzüglich schriftlich mitzuteilen, bei Pfändungen unter Beifügung des Pfändungsprotokolls.</LI>
                    <LI>Im Fall der Nichteinhaltung der in den Ziffern 1. (2) und 2. festgelegten Verpflichtungen des Käufers hat der Verkäufer das Recht, vom Vertrag zurückzutreten und die Ware heraus zu verlangen.</LI>
                </OL>

                <h2>VII. Gefahrübergang</h2>
                <OL>
                    <LI>Die Gefahr, trotz Verlustes oder Beschädigung den Kaufpreis zahlen zu müssen, geht mit der Übergabe auf den Käufer über.</LI>
                </OL>

                <h2>VIII. Annahmeverzug</h2>
                <OL>
                    <LI>Wenn der Käufer nach Ablauf einer ihm schriftlich zu setzenden angemessenen Nachfrist unter Androhung, nach fruchtlosem Fristablauf vom Vertrag zurückzutreten oder Schadensersatz statt der Leistung zu verlangen, stillschweigt oder ohne Rechtsgrund die Zahlung und/oder die Annahme ausdrücklich verweigert, bleibt der Anspruch des Verkäufers auf Vertragserfüllung bestehen. Stattdessen kann er vom Vertrag zurücktreten und/oder Schadensersatz statt der Leistung nach Maßgabe der Ziff 3. verlangen.</LI>
                    <LI>(1) Soweit der Verzug des Käufers länger als einen Monat dauert, hat der Käufer anfallende Lagerkosten zu zahlen. (2) Der Verkäufer kann sich zur Lagerung auch einer Spedition bedienen.</LI>
                    <LI>(1) Als Schadensersatz statt der Leistung bei Verzug des Käufers gem. Ziff. 1 kann der Verkäufer 40 % des Kaufpreises ohne Abzüge fordern, sofern der Käufer nicht nachweist, dass ein Schaden überhaupt nicht oder nicht in Höhe der Pauschale entstanden ist. (2) Im Falle besonders hoher Schäden, wie z. B. bei Sonderanfertigungen, bleibt dem Verkäufer vorbehalten, an Stelle der Schadensersatzpauschale in Abs. (1) einen nachgewiesenen höheren Schaden geltend zu machen.</LI>
                </OL>

                <h2>IX. Rücktritt</h2>
                <OL>
                    <LI>Der Verkäufer braucht nicht zu liefern, wenn der Hersteller die Produktion der bestellten Ware eingestellt hat oder Fälle höherer Gewalt vorliegen, sofern diese Umstände erst nach Vertragsabschluß eingetreten sind, zum Zeitpunkt des Vertragsabschlusses nicht vorhersehbar waren und der Verkäufer die Nichtbelieferung nicht zu vertreten hat und er ferner nachweist, sich vergeblich um Beschaffung gleichartiger Ware bemüht zu haben. Über die genannten Umstände hat der Verkäufer den Käufer unverzüglich zu benachrichtigen und ihm die erbrachten Gegenleistungen unverzüglich zu erstatten.</LI>
                    <LI>Ein Rücktrittsrecht wird dem Verkäufer zugestanden, wenn der Käufer über die für seine Kreditwürdigkeit wesentlichen Tatsachen unrichtige Angaben gemacht hat, die den Leistungsanspruch des Verkäufers in begründeter Weise zu gefährden geeignet sind. Gleiches gilt, wenn der Käufer wegen objektiver Zahlungsunfähigkeit seine Zahlungen einstellt oder über sein Vermögen ein Insolvenzverfahren beantragt wurde. Für die Warenrücknahme gilt Ziff. X.</LI>
                </OL>

                <h2>X. Warenrücknahme</h2>
                <P>Im Falle eines Rücktritts und der Rücknahme gelieferter Waren hat der Verkäufer Anspruch auf Ausgleich der Aufwendungen, Gebrauchsüberlassung und Wert- minderung wie folgt:</P>
                <OL>
                    <LI>Für infolge des Vertrages gemachte Aufwendungen wie Transport- und Montagekosten usw. Ersatz in entstandener Höhe.</LI>
                    <LI>Für Wertminderung und Gebrauchsüberlassung der gelieferten Waren gelten, sofern kein Verbraucherkreditgeschäft vorliegt, folgende Pauschalsätze: </LI>
                    <LI>
                        <table cellspacing="5">
                            <tbody>
                                <tr>
                                    <td colspan="3">Für Möbel, mit Ausnahme von Polsterwaren bei Rücktritt und Rücknahme nach Lieferung:</td>
                                </tr>
                                <tr>
                                    <td> i. d. 1. Hj.</td>
                                    <td> 40 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 2. Hj.</td>
                                    <td> 50 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 3. Hj.</td>
                                    <td> 60 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 4. Hj.</td>
                                    <td> 70 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 3. J.</td>
                                    <td> 80 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 4. J.</td>
                                    <td> 90 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="5">
                            <tbody>
                                <tr>
                                    <td colspan="3">Für Polsterwaren beträgt die Wertminderung bei Rücktritt und Rückgabe nach Lieferung:</td>
                                </tr>
                                <tr>
                                    <td> i. d. 1. Hj.</td>
                                    <td> 50 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 2. Hj.</td>
                                    <td> 60 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 3. Hj.</td>
                                    <td> 70 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 4. Hj.</td>
                                    <td> 80 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                                <tr>
                                    <td> i. d. 3. J.</td>
                                    <td> 90 v. H.</td>
                                    <td> des Kaupreises ohne Abzüge</td>
                                </tr>
                            </tbody>
                        </table>
                        <P>Gegenüber unseren pauschalen Ansprüchen bleibt dem Käufer der Nachweis offen, dass dem Verkäufer keine oder nur eine geringere Einbuße entstanden ist.</P>

                    </LI>
                    <LI>Die Ziffern 1. und 2. gelten nicht für die Rückabwicklung des Vertrages infolge wirksamen Rücktritts nach erfolgloser Nacherfüllung sowie für die Fälle des Widerrufs und dem damit verbundenen uneingeschränkten Rückgaberecht des Käufers bei Verbraucherverträgen nach den §§ 355 ff. BGB.</LI>
                </OL>
                
                <h2>Gewährleistung</h2>
                <OL>
                    <LI>Dem Käufer steht zur Behebung eines Mangels zunächst das Recht auf Nacherfüllung zu, wobei er das Wahlrecht zwischen Mangelbeseitigung (Nachbesserung) oder Ersatzlieferung einer mangelfreien Ware hat.</LI>
                    <LI>Der Verkäufer kann die Nachbesserung bzw. Ersatzlieferung verweigern, wenn sie nur mit unverhältnismäßigen Kosten möglich ist und die andere Art der Nacherfüllung ohne erhebliche Nachteile für den Käufer bleibt.</LI>
                    <LI>Der Käufer kann vom Vertrag zurücktreten oder die Herabsetzung des Kaufpreises verlangen, wenn die Nacherfüllung fehlgeschlagen ist oder nicht in angemessener Frist erbracht wurde oder vom Verkäufer endgültig verweigert wurde.</LI>
                    <LI>Wählt der Käufer nach Ziff. 3 den Rücktritt, so hat er die mangelhafte Ware zurück zu gewähren. Im Übrigen gelten die Bestimmungen des BGB.</LI>
                    <LI>Die Gewährleistung erstreckt sich nicht auf solche Schäden, die der Käufer zu vertreten hat, wie z. B. Schäden, die beim Käufer durch natürliche Abnutzung, Feuchtigkeit, starke Erwärmung der Räume, intensive Bestrahlung mit Sonnen - oder Kunstlicht, sonstige Temperatur- oder Witterungseinflüsse oder unsachgemäße Behandlung entstanden sind.</LI>
                    <LI>Gewährleistungsansprüche verjähren entsprechend der jeweiligen gesetzlichen Regelung; die Gewährleistungsfrist beginnt mit der Übergabe zu laufen.</LI>
                    <LI>Im Übrigen bleibt die Haftung für vereinbarte Beschaffenheiten unberührt.</LI>
                </OL>

                <h2>XII bzw. XIII. Gerichtsstand und Erfüllungsort</h2>
                <OL>
                    <LI>Für Gerichtsstand und Erfüllungsort gelten grundsätzlich die gesetzlichen Regelungen der Zivilprozessordnung bzw. des Bürgerlichen Gesetzbuches.</LI>
                    <LI>Wenn der Käufer keinen allgemeinen Gerichtsstand im Inland hat, nach Vertragsabschluß seinen Wohnsitz oder gewöhnlichen Aufenthaltsort aus dem Inland verlegt oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist Erfüllungsort und Gerichtsstand der Hauptsitz des Verkäufers.</LI>
                </OL>
            </Container>
        </Layout>);
}

export default AGB;